body {
  margin: 0;
  padding-top: 10rem;
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-weight: 400;
}

button {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
}

.header {
  height: auto;
  background-color: #2c3e50;
  /* background-image: url(../src/images/header-new.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.header-font {
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: 'IBM Plex Sans Thai';
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: black;
}

.carousel-img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.carousel-bar {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.section-line {
  border: 1px solid #FE5907 !important;
}

.section-header-white {
  padding-top: 1rem;
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-weight: 600;
  color: #ffffff;
}

.live-score-section-header-white {
  padding-top: 1rem;
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-weight: 200;
  color: #ffffff;
}

.news-thumb-img {
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  object-fit: 'scale-down';
}

.match-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 2.5rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.match-sub-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.text-field-filter{
  background:  #393939 !important;
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  color: #FFFFFF !important;
}

.news-short-desc {
  font-family: 'Bai Jamjuree' !important;
  color: #ffffff !important;
}

.news-short-desc-2 {
  font-family: 'Bai Jamjuree' !important;
  color: #ffffff !important;
  font-size: 1.5rem !important;
}

.match-card-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 300 !important;
  font-size: 2rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.match-card-body {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 300 !important;
  font-size: 1.1rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.news-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-header-old {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  color: #2c3e50 !important;
  /* color: #FFFFFF !important; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-header-play {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 3.0rem !important;
  color: #2c3e50 !important;
  /* background-color: #ffffff !important; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 20px;
}

.news-header-play-tablet {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 3.0rem !important;
  color: #2c3e50 !important;
  /* background-color: #ffffff !important; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 50px;
}

.news-header-play-mobile {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 3.0rem !important;
  color: #2c3e50 !important;
  /* background-color: #ffffff !important; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 50px;
}

.news-header-overlay {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #2c3e50 !important;
  background-color: #ffffff !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-short-desc-old {
  font-family: 'Bai Jamjuree' !important;
  /* color: #ffffff !important; */
}

.news-short-desc {
  font-family: 'Bai Jamjuree' !important;
  color: #ffffff !important;
}

.fixture-short-desc {
  font-family: 'IBM Plex Sans Thai' !important;
  color: #ffffff !important;
  font-size: 14pt !important;
}


.article2 {
  position: relative;
  font-family: 'IBM Plex Sans Thai' !important;
  padding-left: 0rem;
  padding-right: 0rem;
  font-weight: 400;
  color: #000;
}

.article2 p,
.article2 p>span,
.article2>blockquote>span,
.article2>p>a {
  font-family: 'IBM Plex Sans Thai' !important;
  font-size: 1rem !important;
}

.article2 strong,
.article2 strong>span,
.article2>blockquote>span>strong {
  font-family: 'IBM Plex Sans Thai' !important;
  font-size: 1rem !important;
  font-weight: 500;
}

.article2 img {
  width: 100%;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.article2 iframe {
  width: 100% !important;
}

.article-new {
  position: relative;
  font-family: 'IBM Plex Sans Thai' !important;
  padding-left: 0rem;
  padding-right: 0rem;
  font-weight: 300;
  color: #ffffff;
}

.article-new p,
.article-new p>span,
.article-new>blockquote>span,
.article-new>p>a {
  font-family: 'IBM Plex Sans Thai' !important;
  font-size: 1rem !important;
  color: #ffffff;
}

.article-new strong,
.article-new strong>span,
.article-new>blockquote>span>strong {
  font-family: 'IBM Plex Sans Thai' !important;
  font-size: 1rem !important;
  font-weight: 500;
  color: #ffffff;
}

.article-new img {
  width: 100%;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.article-new iframe {
  width: 100% !important;
}

.card-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-bottom: -50px; */
}

.card-body-desc {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 300 !important;
  /* font-size: 0.5rem !important; */
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
          line-clamp: 6; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-top: -10%; */
}

.card-body-desc p,
.card-body-desc p>span,
.card-body-desc>blockquote>span,
.card-body-desc>p>a {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-size: 1rem !important;
  margin-top: -20px;
  color: #ffffff;
}

.card-body-desc img {
  display: none; 

}

.tab-label{
  /* font-size: 1rem !important; */
  /* margin-top: -20px; */
  color: #FFFFFF !important;
}

.result-table-label{
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-size: 1rem !important;
  color: #ffffff;
  /* max-width: 8ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

@media screen and (max-width: 450px) {
  .result-table-label{
    font-family: 'IBM Plex Sans Thai', sans-serif !important;
    font-size: 1rem !important;
    color: #ffffff;
    max-width: 8ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1440px) {
  .screen-match{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .screen-match-left{
    padding-left: 4rem !important;
    margin-top: 1rem !important;
    /* margin-right: 3rem !important; */
  }
  .screen-match-right{
    /* margin-left: 3rem !important; */
    padding-right: 3rem !important;
  }
}

/* @media screen and (max-width: 1024px) {
  .score-board-big{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
} */
/* .score-board-big{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
} */

.match-header-small {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.75rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.match-incident-small{

  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 300 !important;
  font-size: 1.25rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.match-sub-header-small {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 300 !important;
  font-size: 0.75rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* padding-right: 1rem !important; */
}

.logo-img {
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.web-bg {
  background-color: #111111;
  margin-top: -5rem;
}

.card-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 28px !important;
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-body-desc {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  /* font-weight: 300 !important; */
  font-size: 0.5rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* number of lines to show */
          line-clamp: 7; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 12px;
}

.card-body-desc p,
.card-body-desc p>span,
.card-body-desc>blockquote>span,
.card-body-desc>p>a {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  /* font-weight: 300 !important; */
  font-size: 14px !important;
  /* margin-top: 12px; */
  color: #ffffff;
}

.card-body-desc img {
  display: none; 
}

.card-footer {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.card-big-img{
  height: 281px;
  width: 'auto';
  display: block;
}

.card-middle-img{
  height: 147px;
  width: 'auto';
  display: block;
}

.card-small-img{
  height: 77px;
  width: 'auto';
  display: block;
}

.card-chip-desc {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  /* font-weight: 300 !important; */
  font-size: 0.5rem !important;
  /* color: #2c3e50 !important; */
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-top: -10%; */
}

.card-middle-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
  /* line-height: 0.5ex; */
  height: 5ex; /* 2.5ex for each visible line */
  overflow: hidden;
  /* padding-bottom: 10px; */
}

.card-small-header {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* padding-bottom: 20px; */
}

.list-title {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  /* font-weight: 600 !important; */
  font-size: 14px !important;
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 0; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* padding-bottom: 32px; */
}

.list-subtitle {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  /* font-weight: 600 !important; */
  font-size: 12px !important;
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 0; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* padding-bottom: 32px; */
}

.fixture-short-desc {
  font-family: 'IBM Plex Sans Thai' !important;
  color: #ffffff !important;
  font-size: 12px !important;
}

.section-header-white2 {
  /* padding-top: 1rem; */
  font-family: 'IBM Plex Sans Thai' !important;
  font-weight: 600 !important;
  font-size: 28px !important;
  color: #ffffff;
}

.section-header-white3 {
  /* padding-top: 1rem; */
  font-family: 'IBM Plex Sans Thai' !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #ffffff;
}

.card-match-desc {
  font-family: 'IBM Plex Sans Thai' !important;
  color: #ffffff !important;
  font-size: 14px !important;
}

.card-match-name {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  /* font-weight: 600 !important; */
  font-size: 16px !important;
  color: #FFFFFF !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 0; 
  -webkit-box-orient: vertical;
  /* max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  /* padding-bottom: 32px; */
}